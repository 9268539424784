<template>
  <div>
    <vx-card title="Revenue" :is-refresh="isLoading">
      <template slot="actions">
        <vs-select class="vs-select-small" :value="null" v-model="params.period" @input="getData">
          <vs-select-item class="vs-select-small" v-for="(it, index) in ['YEARLY', 'MONTHLY']" :key="index" :value="it" :text="it"/>
        </vs-select>
      </template>
      <div slot="no-body" class="p-6 pb-0">
        <div class="sm:flex">
          <div class="mr-6" v-for="legend in chartData.legends" :key="legend.key">
            <p class="mb-1 font-semibold">{{ legend.alias }}</p>
            <p class="text-3xl" :class="{'text-success': legend.value > 0}"><sup class="text-base mr-1">Rp</sup>{{ legend.value | idr(0) }}</p>
          </div>
        </div>
        <vue-apex-charts
          type="line"
          height="266"
          :options="chartOptions"
          :series="chartData.series" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import VueApexCharts from 'vue-apexcharts'
import { abbreviateNumber } from '@/utilities/common/global-methods'

export default {
  name: 'Revenue',
  components: {
    VueApexCharts
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        period: 'YEARLY'
      },
      data: {
        queryResult: []
      },
      chartData: {
        legends: [],
        categories: [],
        series: []
      },
      chartOptions: {
        chart: {
          toolbar: { show: false },
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.10
          }
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0, 8],
          width: [4, 2]
        },
        grid: {
          borderColor: '#e7e7e7'
        },
        legend: {
          show: true
        },
        colors: ['#F97794', '#b8c2cc'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#7367F0', '#b8c2cc'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0,
          hover: {
            size: 5
          }
        },
        xaxis: {
          labels: {
            style: {
              cssClass: 'text-grey fill-current'
            }
          },
          axisTicks: {
            show: false
          },
          categories: [],
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              cssClass: 'text-grey fill-current'
            },
            formatter (val) {
              return val > 999 ? abbreviateNumber(val.toFixed()) : val
            }
          }
        },
        tooltip: {
          x: { show: false }
        }
      }
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 74
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          // this.data = response.data
          this.chartData = response.data.chartData
          this.chartOptions.xaxis.categories = response.data.chartData.categories
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
